import FacePhotoForm from '~/components/wizard/FacePhotoForm'
import TeethPhotoForm from '~/components/wizard/TeethPhotoForm'

interface WizardConfigProps {
	question: string
	image?: string
	name: string
	options?: Array<{ label: string; value: string }>
	component?: JSX.Element
}

export const getWizardConfig = (
	setPhotosStepDisabled: (photosStepDisabled: boolean) => void,
	setFieldValue: (key: string, value: any) => void
): WizardConfigProps[] => [
	{
		question: 'What is your dream outcome?',
		name: 'dream_outcome',
		image: '/wizard/wizard_image_1.jpg',
	},
	{
		question: 'What are your reasons for wanting treatment?',
		name: 'treatment_reason',
		image: '/wizard/wizard_image_2.jpg',
	},
	{
		question: 'How did you hear about Ruh Dental?',
		image: '/wizard/wizard_image_3.jpg',
		name: 'comes_from',
		options: [
			{
				label: 'Instagram',
				value: 'instagram',
			},
			{
				label: 'Facebook',
				value: 'facebook',
			},
			{
				label: 'TikTok',
				value: 'tiktok',
			},
			{
				label: 'Search Engine',
				value: 'search_engine',
			},
			{
				label: 'Recommendation',
				value: 'recommendation',
			},
			{
				label: 'Walk By',
				value: 'walk_by',
			},
			{
				label: 'Other',
				value: 'other',
			},
		],
	},
	{
		question: "Let's take some photos!",
		name: 'face_photos',
		component: (
			<FacePhotoForm setPhotosStepDisabled={setPhotosStepDisabled} setFieldValue={setFieldValue} />
		),
	},
	{
		question: 'Show all your teeth',
		name: 'teeth_photos',
		component: (
			<TeethPhotoForm setPhotosStepDisabled={setPhotosStepDisabled} setFieldValue={setFieldValue} />
		),
	},
]
