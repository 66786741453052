import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Button, Text } from '@chakra-ui/react'
import { FaGlasses } from 'react-icons/fa'
import { IoIosFlash } from 'react-icons/io'
import { RxCamera } from 'react-icons/rx'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { ExportInterface } from 'react-images-uploading/dist/typings'

import React, { useEffect } from 'react'

const iconsConfig = [
	{
		icon: <FaGlasses size={20} />,
		text: 'Remove glasses',
	},
	{
		icon: <RxCamera size={20} />,
		text: 'Stay close to the camera',
	},
	{
		icon: <IoIosFlash size={20} />,
		text: 'Use the flash',
	},
]

export interface PhotoFormProps {
	setPhotosStepDisabled: (value: boolean) => void
	setFieldValue: (key: string, value: any) => void
}

export const FacePhotoForm: React.FC<PhotoFormProps> = ({
	setPhotosStepDisabled,
	setFieldValue,
}) => {
	const [images, setImages] = React.useState([])
	const maxNumber = 1

	const onChange = (imageList: ImageListType, _addUpdateIndex: number[] | undefined) => {
		// data for submit
		setImages(imageList as never[])
		setFieldValue('face_photos', imageList[0]?.dataURL || '')
	}

	useEffect(() => {
		setPhotosStepDisabled(images.length !== 1)
	}, [images])

	const renderUploaderItem = (props: ExportInterface, index: number, title: string) => {
		return (
			<Box>
				<Text fontSize='1em' color='#323E48' mb={3}>
					{title}
				</Text>
				<Box sx={{ display: 'flex', gap: 4 }}>
					<Box
						sx={{
							height: '220px',
							width: '165px',
							backgroundColor: '#F5F7FB',
							borderRadius: '8px',
							display: 'flex',
							flexDirection: 'column',
							border: '1px solid #A5B0BA',
							overflow: 'hidden',
							img: {
								width: '100%',
								height: '220px',
							},
							'@media (max-width: 550px)': {
								width: '130px',
								height: '190px',
							},
						}}
					>
						<img src='/wizard/wizard_example.jpg' alt='' />
					</Box>
					<Box
						sx={{
							width: '165px',
							height: '220px',
							'@media (max-width: 550px)': {
								width: '130px',
								height: '190px',
							},
						}}
					>
						{props.imageList.length > index ? (
							<Box
								sx={{
									background: '#F5F7FB',
									position: 'relative',
									borderRadius: '8px',
									width: '100%',
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<img src={props.imageList[index].dataURL} alt='' />
								<Button
									onClick={() => {
										const removingImageURL = props.imageList[index].dataURL
										const newImages = props.imageList.filter(
											image => image.dataURL !== removingImageURL
										)
										setImages(newImages as never[])
										props.onImageRemove(index)
									}}
									sx={{
										position: 'absolute',
										padding: 0,
										background: 'rgba(0,0,0,0.6)',
										'&:hover': { background: 'rgba(0,0,0,0.8)' },
									}}
								>
									<DeleteIcon color='#fff' />
								</Button>
							</Box>
						) : (
							<Button
								onClick={props.onImageUpload}
								{...props.dragProps}
								sx={{
									border: props.isDragging ? '2px dashed #D2196C' : '2px dashed #A5B0BA',
									width: '100%',
									height: '100%',
									backgroundColor: '#F5F7FB',
									color: props.isDragging ? '#D2196C' : '#A5B0BA',
									borderRadius: '8px',
									padding: '10px',
									display: 'flex',
									flexDirection: 'column',
									cursor: 'pointer',
								}}
							>
								<AddIcon />
								<Text fontSize='0.8em' color='#A5B0BA' textAlign='center' mt={3}>
									upload your photo
								</Text>
							</Button>
						)}
					</Box>
				</Box>
			</Box>
		)
	}

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				'@media (max-width: 550px)': {
					width: '100%',
					flexDirection: 'column',
				},
			}}
		>
			<Box
				sx={{
					width: '100px',
					gap: 5,
					display: 'flex',
					flexDirection: 'column',
					marginRight: '50px',
					'@media (max-width: 550px)': {
						marginRight: '0',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
						marginBottom: '15px',
					},
				}}
			>
				{iconsConfig.map(item => {
					return (
						<Box
							key={item.text}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<Box
								sx={{
									width: '60px',
									height: '60px',
									background: '#FFF8FB',
									borderRadius: '50%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#D53F8C',
								}}
							>
								{item.icon}
							</Box>
							<Text fontSize='0.8em' color='#323E48' textAlign='center'>
								{item.text}
							</Text>
						</Box>
					)
				})}
			</Box>
			<Box>
				<ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber}>
					{props => (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 10,
								'@media (max-width: 550px)': {
									width: '100%',
									justifyContent: 'center',
								},
							}}
						>
							{renderUploaderItem(props, 0, 'Big smile')}
						</Box>
					)}
				</ImageUploading>
			</Box>
		</Box>
	)
}

export default FacePhotoForm
