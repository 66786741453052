import { useLazyQuery } from '@apollo/client'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'

import WizardForm from '~/components/wizard/WizardForm'
import { PersonDetails, PersonDetailsVariables } from '~/inputs/__generated__/PersonDetails'
import { personDetailQuery } from '~/inputs/person'

const textStyles = {
	fontSize: '1em',
	color: '#323E48',
	textAlign: 'center',
	marginTop: '10px',
	'@media (max-width: 1250px)': {
		fontSize: '0.8em',
	},
	'@media (max-width: 550px)': {
		textAlign: 'left',
	},
}

interface WizardProps {
	personId: string
}

const Wizard = ({ personId }: WizardProps) => {
	const [fetchPersonDetails, { data: personDetails, loading: personDetailsLoading }] = useLazyQuery<
		PersonDetails,
		PersonDetailsVariables
	>(personDetailQuery)

	const [showWelcomePage, setShowWelcomePage] = useState<boolean>(false)
	const [showFinishPage, setShowFinishPage] = useState<boolean>(false)

	useEffect(() => {
		personId &&
			fetchPersonDetails({
				variables: { personId },
			})
	}, [personId])

	useEffect(() => {
		const wizardStep = personDetails?.person?.wizard_step
		if (wizardStep === 5) {
			setShowFinishPage(true)
		} else if (wizardStep > 0) {
			setShowWelcomePage(false)
		} else if (wizardStep === null) {
			setShowWelcomePage(true)
		}
	}, [personDetails])

	if (personDetailsLoading)
		return (
			<Flex justify='center' gap='1em' p='1em'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)

	if (!personDetails?.person) {
		return (
			<Flex justify='center' gap='1em' p='1em'>
				<Text color='gray.500' fontSize='sm'>
					We are sorry, but we could not find the person you are looking for.
				</Text>
			</Flex>
		)
	}

	const renderContent = () => {
		if (showWelcomePage) {
			return (
				<Box
					sx={{
						width: '100%',
						padding: '20px',
						borderRadius: '12px',
						minHeight: '500px',
						background: '#fff',
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						flexDirection: 'column',
						'@media (max-width: 550px)': {
							minHeight: '350px',
						},
					}}
				>
					<Text
						fontSize='2em'
						color='#323E48'
						textAlign='center'
						sx={{
							'@media (max-width: 1250px)': {
								fontSize: '1.5em',
							},
							'@media (max-width: 550px)': {
								fontSize: '1.2em',
								lineHeight: '1.2em',
							},
						}}
					>
						Complete your Triage Smile Assessment with Rüh Dental
					</Text>
					<Text sx={textStyles} maxWidth='600px'>
						Just upload a few photos of your teeth and this will aid our team in triaging you to the
						best dentist for your requirements and desires.
					</Text>
					<Text sx={textStyles}>How it works:</Text>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 5,
							'@media (max-width: 550px)': {
								gap: 3,
							},
						}}
						mt={2}
					>
						<Text sx={textStyles}>1. Fill in the form</Text>
						<Text sx={textStyles}>2. Upload your photos</Text>
						<Text sx={textStyles}>3. Submit them for review</Text>
					</Box>
					<Button mt={8} onClick={() => setShowWelcomePage(false)} colorScheme='pink'>
						Start
						<ChevronRightIcon fontSize={20} />
					</Button>
				</Box>
			)
		}
		if (showFinishPage) {
			return (
				<Box
					sx={{
						width: '100%',
						padding: '20px',
						borderRadius: '12px',
						minHeight: '500px',
						background: '#fff',
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Text
						fontSize='2em'
						color='#323E48'
						textAlign='center'
						sx={{
							'@media (max-width: 1250px)': {
								fontSize: '1.5em',
							},
						}}
					>
						Thank you for uploading your photos! We look forward to meeting you soon
					</Text>
				</Box>
			)
		}
		if (personDetails?.person?.wizard_step !== 5) {
			return <WizardForm personId={personId} savedWizardStep={personDetails?.person?.wizard_step} />
		}
		return null
	}

	return (
		<Flex
			direction='column'
			h='full'
			sx={{
				backgroundImage: 'url(/wizard_bg.jpg)',
				backgroundSize: 'cover',
			}}
		>
			<Box
				sx={{
					width: '1200px',
					margin: '0 auto',
					padding: '0 15px',
					'@media (max-width: 1250px)': {
						width: '768px',
					},
					'@media (max-width: 790px)': {
						width: '100%',
					},
				}}
			>
				<Flex
					sx={{
						width: '100%',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box
						sx={{
							width: '240px',
							height: '150px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '20px',
							backgroundColor: '#fff',
							borderBottom: '2px solid #D2196C',
							'@media (max-width: 790px)': {
								width: '150px',
								height: '120px',
								padding: '10px',
							},
						}}
					>
						<img src='/ruh_logo_wizard.svg' alt='Ruhdental' />
						<Text
							fontSize='1em'
							color='#323E48'
							textAlign='center'
							ml={2}
							sx={{
								span: {
									marginLeft: '4px',
									fontWeight: 'bold',
									b: {
										color: '#D2196C',
									},
								},
								'@media (max-width: 790px)': {
									fontSize: '0.8em',
								},
							}}
						>
							Powered by
							<span>
								Lead<b>Storm®</b>
							</span>
						</Text>
					</Box>
					<Box
						sx={{
							width: '300px',
							'@media (max-width: 790px)': {
								width: '200px',
							},
						}}
					>
						<img src='/leadstorm_logo_wizard.svg' alt='LeadStorm' />
					</Box>
				</Flex>
				{renderContent()}
			</Box>
		</Flex>
	)
}

export default Wizard
